import { LifeNetUtilService } from '../core/utils/life-net-util.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { EnvironmentConfigRestService } from '../core/rest-services/environment-config-rest.service';
import { WindowService } from '../core/window.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { WalkMeUtilService } from '../core/utils/walk-me-util.service';
import { environment } from '../../environments/environment.prod';
import { localeSplitter } from '../core/core-constants.service';
import { ConfigLoaderService } from '../config-loader.service';
import { RegistrationModalComponent } from '../shared/modal-popup/registration-modal/registration-modal-component';
import { IcCodeModalComponent } from '../shared/modal-popup/ic-code-modal/ic-code-modal.component';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DomUtilService } from '../core/utils/dom-util.service';
import { forEach, isEqual, keys, range, startsWith } from 'lodash-es';
import { MedalliaUtilService } from '../core/utils/medallia-util.service';
import { SupportService } from 'app/core/services/support/support-service';
import {DeviceDetectionService} from "../core/services/device-detection.service";

@Component({
  selector: 'hl-front-page',
  templateUrl: './front-page.component.html'
})
export class FrontPageComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  readonly welcomeLanguageKeys = {
    de_DE: 'Deutsch',
    en_GB: 'English',
    es_ES: 'Español',
    fr_FR: 'Français',
    it_IT: 'Italiano',
    ja_JP: '日本語 (Japanese)',
    ko_KR: '한국어 (Korean)',
    hu_HU: 'Magyar',
    nl_BE: 'Nederlands',
    no_NO: 'Norsk',
    zh_CN: '中文 (Chinese)',
    pl_PL: 'Język polski',
    pt_PT: 'Português',
    ru_RU: 'Pуѝѝкий (Russian)',
    sk_SK: 'Slovenský',
    sv_SE: 'Svenska',
    tr_TR: 'Türkçe',
    cz_CZ: 'Čeština',
    bg_BG: 'Български',
    vi_VN: 'Tiếng Việt',
    in_ID: 'Bahasa Indonesia'
  };
  notLoggedInAvatarImage = environment.version + 'assets/images/not-logged-in.png';

  @ViewChild('dropDownToggle') dropDownToggleEl: ElementRef;
  @ViewChild('dropDownLanguageToggle') dropDownLanguageToggleEl: ElementRef;
  @ViewChild('registerDialog') registerDialog: RegistrationModalComponent;
  @ViewChild('icCodeModal') icCodeModal: IcCodeModalComponent;

  @Input()
  showUserPanel = true;

  singleCountryAllowedForRegistration: string;
  iccodeFragment: string;
  selectedLanguage: string;
  langCollapsed: boolean;
  copyRightLeftLabel: string;
  footersLinks: string[];

  defaultLanguage = '';
  allowedLanguageKeys: string[] = [];
  languages = [];
  glnFile = this.configLoaderService.getDefaultTranslateFile();

  window = this.windowService.nativeWindow;

  isDropdownOpen = false;
  isDropdownLanguageOpen = false;
  showLanguageDropdown = true;
  caseCreationMultipleAps = false;

  removedElements: HTMLElement[] = [];
  isMobileApp = false;

  private readonly unsubscribe$ = new Subject<void>();
  private readonly DEFAULT_LANGUAGE = 'en_GB';

  constructor(private windowService: WindowService,
              private environmentConfigRestService: EnvironmentConfigRestService,
              private configService: CountryConfigRestService,
              private lifeNetUtilService: LifeNetUtilService,
              private translateService: TranslateService,
              private walkMeUtilService: WalkMeUtilService,
              private medalliaUtilService: MedalliaUtilService,
              private configLoaderService: ConfigLoaderService,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private supportService: SupportService,
              private deviceDetectionService: DeviceDetectionService,
              @Inject(DOCUMENT) private document: HTMLDocument) {
  }

  ngOnInit() {
    this.environmentConfigRestService.getEnvironmentConfig().pipe(takeUntil(this.unsubscribe$))
      .subscribe((config) => {
        this.defaultLanguage = config.WELCOME_PAGE_DEFAULT_LANGUAGE || this.DEFAULT_LANGUAGE;
        this.allowedLanguageKeys = config.WELCOME_LANGUAGE_KEYS ?
          config.WELCOME_LANGUAGE_KEYS.split(',') : keys(this.welcomeLanguageKeys);
        this.showLanguageDropdown = this.allowedLanguageKeys.length !== 1;
        this.caseCreationMultipleAps = isEqual(config['FEATURE_TOGGLE_CASE_CREATION_MULTIPLE_APPS'], 'true')
        const registrationCountryList = config.REGISTRATION_COUNTRY_LIST?.split(',');
        if (registrationCountryList && registrationCountryList.length === 1 && registrationCountryList[0]) {
          this.singleCountryAllowedForRegistration = registrationCountryList[0];
        }
        this.setPreferredLanguage();
        this.initProperties();
      });
    this.supportService.languageChangeSubject.pipe(takeUntil(this.unsubscribe$))
      .subscribe(language => {
        if (Object.keys(this.welcomeLanguageKeys).includes(language)) {
          this.setLanguage(language);
        }
      });
    this.init();
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.dropDownToggleEl.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
    if (this.isDropdownLanguageOpen && !this.dropDownLanguageToggleEl.nativeElement.contains(event.target)) {
      this.isDropdownLanguageOpen = false;
    }
  }

  init() {
    this.removeRedundantElements();
    this.setCopyRightLeftLabelFromGlnConfig();

    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.iccodeFragment = fragment;
      }
    });
    this.isMobileApp = this.deviceDetectionService.isMobileApp();
  }

  setPreferredLanguage() {
    let preferredBrowserLanguage = this.getBrowserLanguage();
    if (!preferredBrowserLanguage) {
      preferredBrowserLanguage = this.defaultLanguage;
    }
    this.setLanguage(preferredBrowserLanguage);
  }

  getBrowserLanguage(): string {
    const navigator: any = this.window.navigator;
    let navigatorLanguage: string;

    // Support all browser specific properties
    const browserLanguagePropertyKeys = ['language', 'browserLanguage',
      'systemLanguage', 'userLanguage'];

    forEach(browserLanguagePropertyKeys, (key) => {
      if (navigator[key] && navigator[key].length) {
        navigatorLanguage = navigator[key];
      }
    });

    if (navigatorLanguage) {
      navigatorLanguage = this.getMappedLanguage(navigatorLanguage);
    }
    return navigatorLanguage;
  }

  getMappedLanguage(navigatorLanguage): string {
    let mappedLanguage: string;
    const language = navigatorLanguage.replace(/[\-_]([a-zA-Z]*)/g, '');

    forEach(this.allowedLanguageKeys, (key) => {
      if (startsWith(key, language)) {
        mappedLanguage = key;
        return;
      }
    });
    return mappedLanguage;
  }

  setLanguage(language) {
    const languageSplit = language.split(localeSplitter);

    this.selectedLanguage = languageSplit[0];
    const countryCode = languageSplit[1];

    // set translation and configuration
    this.lifeNetUtilService.setTranslationFile(language).subscribe(() => {
      // We figured out (empirically), that the call to getTranslation (
      // must be called after the translation file (for the browser language) is set.
      // Otherwise, the UI language is set to the default en_GB.
      this.setFooterLinks();
      if (this.registerDialog) {
        this.registerDialog.updateTranslations();
      }
    });
    this.lifeNetUtilService.setConfigurationFile(countryCode);
    if (this.caseCreationMultipleAps) {
      this.supportService.configChangeSubject.next(countryCode);
    }

    this.langCollapsed = true;

    this.walkMeUtilService.setLanguageAndCountry(window, this.selectedLanguage, '');
    this.medalliaUtilService.update();
    DomUtilService.replaceUnsupportedFont(this.selectedLanguage);
  }

  initProperties() {
    this.langCollapsed = true;
    this.languages = [];

    this.languages = this.allowedLanguageKeys.map(key => ({
        key,
        language: this.welcomeLanguageKeys[key]
      })
    );
  }

  public setFooterLinks() {
    this.translateService.getTranslation(this.glnFile).subscribe(gln => {
      this.footersLinks = gln;
    });
  }

  getFooterLink(key: string) {
    if (this.footersLinks) {
      return this.footersLinks[key];
    }
  }

  redirectLogin() {
    // redirect to login page
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.window.location.href = configResponse.LOGIN_URL;
    });
  }

  redirectRegister() {
    if (this.singleCountryAllowedForRegistration) {
      this.configService.getSpecificCountryConfig(this.singleCountryAllowedForRegistration).subscribe(config => {
        this.window.location.href = config.LINK_REGISTER_WELCOME;
      });
    } else if (this.registerDialog) {
      this.registerDialog.show();
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleLanguageDropdown() {
    this.isDropdownLanguageOpen = !this.isDropdownLanguageOpen;
  }

  logout() {
    this.lifeNetUtilService.invalidateSession();
    location.reload();
  }

  /**
   * remove header, overlay, modal, etc. from welcome page if there is some
   */
  removeRedundantElements() {
    this.removeElementsWithID(['header', 'overlay-target']);
    this.removeElementsWithClassName(['overlay__container', 'modal-dialog']);
  }

  removeElementsWithID(ids: string[]) {
    for (const id of ids) {
      const element = this.document.getElementById(id);
      if (element !== null) {
        element.style.display = 'none';
        this.removedElements.push(element);
      }
    }
  }

  removeElementsWithClassName(classNames: string[]) {
    for (const className of classNames) {
      const elementCollection = this.document.getElementsByClassName(className);
      const elements = range(0, elementCollection.length)
        .map(i => elementCollection.item(i) as HTMLElement)
        .filter(e => !!e);
      for (const element of elements) {
        element.style.display = 'none';
        this.removedElements.push(element);
      }
    }
  }

  revealHiddenElements() {
    this.removedElements.forEach(element => element.style.display = null);
  }

  setCopyRightLeftLabelFromGlnConfig() {
    this.configService.getGlnConfig().subscribe((config) => {
      // copyright
      const currentYear = new Date().getFullYear();
      this.copyRightLeftLabel = config.COPYRIGHT_NOTICE + ' ' + currentYear;
    });
  }

  ngAfterViewInit(): void {
    if (this.icCodeModal && this.icCodeModal.shouldShow(this.iccodeFragment)) {
      this.icCodeModal.show();
    }
  }

  // to avoid ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
