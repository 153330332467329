<hl-front-page>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-sm-8 col-md-6 col-lg-5">
          <h1 class="color-orange">Oops! Not authenticated </h1>
          <h3>Possible reasons page was not found:</h3>
          <ul class="list list--bullet">
            <li>If you are accessing LifeNet for the first time, we might need some more minutes to setup your account. Please check if you have
              received a Welcome Email from LifeNet.
            </li>
            <li>If you have accessed LifeNet before, we have to check your user rights. Please contact your support.</li>
          </ul>

          <h5 class="margin-top-3 margin-bottom-0">We are happy to help</h5>
          <p>If you cannot access LifeNet, please let us know.</p>

          <hr>
          <a class="button button--primary" href="https://www.healthcare.siemens.com/how-can-we-help-you" target="_blank" rel="noopener noreferrer">Contact us</a>
        </div>
        <div class="col-8 col-sm-4 col-md-5 col-lg-5 margin-top-3">
          <img src="https://www.healthcare.siemens.com/siemens_hwem-hwem_ssxa_websites-context-root/assets/img/error.png" alt="">
        </div>
      </div>
    </div>
  </div>
</hl-front-page>


 
