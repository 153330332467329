<hl-front-page [showUserPanel]="false">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-sm-8 col-md-6 col-lg-5">
          <h1 class="color-orange" translate="ERROR_404_HEADLINE"></h1>
          <h3 translate="REASONS_PAGE_NOT_FOUND"></h3>
          <div [innerHTML]="'ERROR_404_REASONS' | translate"></div>
        </div>
        <div class="col-8 col-sm-4 col-md-5 col-lg-5 margin-top-3">
          <img src="https://www.healthcare.siemens.com/siemens_hwem-hwem_ssxa_websites-context-root/assets/img/error.png" alt="">
        </div>
      </div>
    </div>
  </div>
</hl-front-page>


 
