import { Injectable } from '@angular/core';
import { SupportRestService } from '../../rest-services/support-rest.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SupportArticle } from '../../models/support/support-article';
import { SupportCase } from '../../models/support/support-case';
import { SupportCaseApplication } from '../../models/support/support-case-application';
import { map, take } from 'rxjs/operators';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { PepUserInfo } from 'app/core/models/support/pep-user-info';
import { SupportCountryCodeToCountryMapping } from '../../models/support/support-case-countries';
import { Params } from '@angular/router';

@Injectable({providedIn: 'root'})
export class SupportService {

  languageChangeSubject = new Subject<string>();
  configChangeSubject = new Subject<string>();
  lastSupportPageQueryParams = new BehaviorSubject<Params>(null);
  private _prefilledApplicationTitle: string;
  private _prefilledApplicationValue: string;

  constructor(
    private supportRestService: SupportRestService,
    private configRestService: CountryConfigRestService
  ) { }

  createSupportCase(supportCase: SupportCase): Observable<any> {
    return this.supportRestService.createSupportCase(supportCase);
  }

  getArticles(language: string): Observable<SupportArticle[]> {
    return this.supportRestService.getArticles(language);
  }

  getSupportCaseApplications(): SupportCaseApplication[] {
    return [
      {id: 'pepconnect', name: 'SUPPORT_CASE_APPLICATION_PEPCONNECT'}
    ];
  }

  getSupportCaseCountries(): Observable<SupportCountryCodeToCountryMapping> {
    return this.configRestService.getConfig().pipe(
      take(1), // grab only the first config as we don't expect support countries to be country specific
      map(config => config['SUPPORT_PEP_PEPCONNECT_COUNTRIES'].split(',')),
      map((countryPairs: string[]) => {
        const countryMap: SupportCountryCodeToCountryMapping = {};

        countryPairs.forEach(pair => {
          const [countryCode, country] = pair.split(':');
          countryMap[countryCode] = country;
        });

        return countryMap;
      })
    );
  }

  getCaseCreationInfo(id: string): Observable<PepUserInfo> {
    return this.supportRestService.getCaseCreationInfo(id);
  }

  get prefilledApplicationTitle(): string {
    return this._prefilledApplicationTitle;
  }

  set prefilledApplicationTitle(application: string) {
    this._prefilledApplicationTitle = application;
  }

  get prefilledApplicationValue(): string {
    return this._prefilledApplicationValue;
  }

  set prefilledApplicationValue(application: string) {
    this._prefilledApplicationValue = application;
  }
}
