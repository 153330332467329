import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';
import { SupportArticle } from '../models/support/support-article';
import { HttpParams } from '@angular/common/http';
import { SupportCase } from '../models/support/support-case';
import { PepUserInfo } from '../models/support/pep-user-info';

@Injectable({providedIn: 'root'})
export class SupportRestService {

  private supportRestName = restEndPoint + 'support';
  private createSupportCaseRestName = this.supportRestName + '/case';
  private caseCreationRestName = restEndPoint + 'case-creation/:id';

  constructor(private http: CacheableHttpClient) {
  }

  private getUrl(url: string, id: string) {
    return url.replace(/:id/g, id);
  }

  createSupportCase(supportCase: SupportCase): Observable<any> {
    return this.http.post(this.createSupportCaseRestName, supportCase);
  }

  getArticles(language: string): Observable<SupportArticle[]> {
    const httpParams = new HttpParams()
      .set('language', language);
    return this.http.get<SupportArticle[]>(this.supportRestName, {params: httpParams});
  }

  getCaseCreationInfo(id: string): Observable<PepUserInfo> {
    return this.http.get(this.getUrl(this.caseCreationRestName, id));
  }
}
