<div class="main-container overflow-scroll-touch">
  <div class="application-bar">
    <div class="application-bar__container">
      <div class="application-bar__logo">
        <div class="logo"></div>
      </div>
      <div class="application-bar__additional-functions">

      </div>
      @if (showUserPanel) {
        <div class="application-bar__meta-menu">
          <div class="meta-menu">
            <ul class="bare-list meta-menu__list">
              <li class="meta-menu__item">
                <a href="https://www.healthcare.siemens.com/how-can-we-help-you" target="_blank" rel="noopener noreferrer" data-cy="contact-us-link"
                  class="meta-menu__link">
                  <i class="icon-email"></i>
                </a>
              </li>
              <!-- LANGUAGE START-->
              @if (showLanguageDropdown) {
                <li class="dropdown dropdown--right meta-menu__item" [class.is-open]="isDropdownLanguageOpen">
                  <a href="javascript:void(0)" class="dropdown__toggle meta-menu__link" (click)="toggleLanguageDropdown()" #dropDownLanguageToggle
                    data-cy="language-dropdown">
                    <i class="icon-world"></i>
                  </a>
                  <div class="menu dropdown__panel">
                    @for (lang of languages; track lang; let i = $index) {
                      <a class="menu__item" (click)="setLanguage(lang.key)" [attr.data-cy]="'language-value-' + (i+1)"><span
                        [innerHTML]="lang.language"></span></a>
                    }
                  </div>
                </li>
              }
              <!-- LANGUAGE END-->
            </ul>
            <div class="dropdown dropdown--right">
              <button href="#" class="button button--control button--off-content dropdown__toggle meta-menu__toggle-button">
                <i class="icon-dots-ellipsis-horizontal"></i>
              </button>
              <div class="dropdown__panel">
                <ul class="menu meta-menu__hidden-list"></ul>
              </div>
            </div>
          </div>
        </div>
      }


      @if (showUserPanel) {
        <div class="application-bar__user-identifier">
          <div class="user-identifier dropdown dropdown--right" [class.is-open]="isDropdownOpen">
            <button class="user-identifier__toggle-button dropdown__toggle" (click)="toggleDropdown()" #dropDownToggle>
              <div class="user-identifier__avatar"><img [attr.src]="notLoggedInAvatarImage" alt="user avatar"/></div>
              <span class="user-identifier__details">
                  <span class="user-identifier__name">
                    <span [translate]="'LABEL_LOGIN'"></span> /
                    <span [translate]="'LABEL_REGISTER'"></span>
                  </span>
                </span>
              <i class="icon-arrow-down-small"></i>
            </button>
            <div class="menu dropdown__panel">
              <a class="menu__item" (click)="redirectLogin()"><span [translate]="'LABEL_LOGIN'"></span></a>
              <a class="menu__item" (click)="redirectRegister()" target="_blank" rel="noopener noreferrer"><span
                [translate]="'LABEL_REGISTER'"></span></a>
            </div>
          </div>
        </div>
      }


    </div>
  </div>

  <div class="container">
    <ng-content></ng-content>
  </div>

  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="legal-links">
            <li class="legal-links__item"><span>{{ copyRightLeftLabel }}</span><span [translate]="'LABEL_COPYRIGHT_RESERVED'"></span></li>
            <li class="legal-links__item"><a class="legal-links__link" [translate]="'IMPRESSUM'" data-cy="corporate-info-link"
              [href]="getFooterLink('IMPRESSUM_LINK') | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
            <li class="legal-links__item"><a class="legal-links__link" [translate]="'DATENSCHUTZ'" data-cy="privacy-policy-link"
              [href]="getFooterLink((isMobileApp ? 'PRIVACY_POLICY_MOBILE_APP_LINK' : 'DATENSCHUTZ_LINK')) | safeDom: 'url'" target="_blank"
              rel="noopener noreferrer"></a></li>
            <li class="legal-links__item"><a class="legal-links__link" [translate]="'NUTZUNGSBEDINGUNGEN'" data-cy="terms-of-use-link"
              [href]="getFooterLink('NUTZUNGSBEDINGUNGEN_LINK') | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
            <li class="legal-links__item"><a class="legal-links__link" [translate]="'EXPORTKONTROLLE'" data-cy="digital-id-link"
              [href]="getFooterLink('EXPORTKONTROLLE_LINK') | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
            <li class="legal-links__item"><a class="legal-links__link" [translate]="'LABEL_DIGITAL_SERVICE_ACT'" data-cy="digital-service-act-link"
              [href]="getFooterLink('DIGITAL_SERVICE_ACT_URL') | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>

<hl-registration-modal #registerDialog></hl-registration-modal>
<hl-ic-code-modal #icCodeModal [isWelcomePage]="true"></hl-ic-code-modal>

